"use client";

import { useAnalytics } from "@/context/AnalyticsContext";
import { useCallback } from "react";
import { convertCalculationDataToAnalyticsPayload } from "./payload";

export const useAnalyticsTrack = () => {
  const analytics = useAnalytics();

  const trackEvent = useCallback(
    (
      eventName: string,
      {
        category,
        interaction,
        action,
        ...properties
      }: {
        category: string;
        interaction: string;
        action: string;

        [key: string]: any; // Allow for any other properties
      }
    ) => {
      const eventData = {
        Category: category,
        Interaction: interaction,
        Action: action,
        path: location.pathname,
        ...properties,
      };

      if (analytics) {
        analytics.track(eventName, eventData);
      } else {
        console.warn(
          "Analytics not available",
          `eventName: ${eventName}`,
          eventData
        );
      }
    },
    [analytics]
  );

  return trackEvent;
};

export const useTrackCalculation = (portMap: Record<string, string>) => {
  const trackEvent = useAnalyticsTrack();

  const trackCalculation = useCallback(
    (
      type: "single" | "combo" | "cfr",
      mode: "simple" | "advanced",
      payload: any
    ) => {
      trackEvent("Calculation", {
        category: "Calculation",
        interaction: "Submit",
        action: "Calculate",

        calculationType: type,
        calculationMode: mode,

        ...convertCalculationDataToAnalyticsPayload(payload, portMap),
      });
    },
    [trackEvent]
  );

  return trackCalculation;
};
