"use client";

import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog";
import {
  CalcContext,
  CalculatorContextT,
  getMonthsArrayWithLabels,
  useCalculator,
  VesselResponse,
  VoyageResult,
  formatNumberWithCommas,
  CalculatorMode,
} from "@copmer/calculator-widget";
import {
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  CalculatorModeString,
  SelectOption,
  SimpleCalculatorFormData,
} from "./types";
import MonthSelector from "./MonthSelector";
import Icon from "./Icon";
import { cn } from "@/lib/utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import DebugTable from "./DebugTable";
import { valueSuffixConstructor } from "./utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { CalculatorDropdown, InputWrapper } from "./Inputs";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Select, SelectItem } from "@radix-ui/react-select";
import {
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Link from "next/link";
import { Ship, X } from "lucide-react";
import { MultiModeInput } from "./schema";
import { isSet } from "./form";
import { useMeasureHeaderHeight } from "@/app/(protected)/_components/navbar/AutoHeaderSpacer";
import { useUpdateCopyData } from "./copy-context";
import { SizeRestrictionItineraryWarning } from "./SizeRestrictionWarning";
import { FormError } from "@/components/form-error";
import { FormInfo } from "@/components/form-success";
import { useTrackCalculation } from "@/components/analytics/hooks";

const isMultiModeInput = (value: any): value is MultiModeInput => {
  return value?.ports !== undefined;
};

export default function CalculationResults({
  hasToleranceParam,
  payload,
  vesselMap,
  portMap,

  sortModalOpen,
  setSortModalOpen,
  filterModalOpen,
  setFilterModalOpen,
  optimizerStatus,
}: {
  hasToleranceParam: boolean;
  payload: SimpleCalculatorFormData | MultiModeInput | null;

  vesselMap: Record<string, VesselResponse>;
  portMap: Record<string, string>;

  sortModalOpen?: boolean;
  setSortModalOpen?: (isOpen: boolean) => void;

  filterModalOpen?: boolean;
  setFilterModalOpen?: (isOpen: boolean) => void;

  optimizerStatus?: {
    status: string | null;
    progress: number | null;
  } | null;
}) {
  const monthsArray: SelectOption[] = getMonthsArrayWithLabels();
  const [activeMonth, setActiveMonth] = useState<string>(monthsArray[0].value);

  const ctx = useContext<CalculatorContextT | null>(CalcContext);

  if (!ctx) {
    throw new Error("No calc context provided");
  }

  const {
    onSubmit,
    isCalculating,
    hasPendingCalculations,
    calculationId,
    plans,
    periodFreightRates,
    calculationsToCopy,
    calculationError,
  } = useCalculator(
    ctx.apiRoutes,
    undefined,
    undefined,
    undefined,
    undefined,
    ctx.isCFROptimizer,
    ctx.onOptimizerTwoStep
  );

  const updateCopyData = useUpdateCopyData();

  useEffect(() => {
    updateCopyData(
      isCalculating || hasPendingCalculations,
      activeMonth,
      calculationsToCopy
    );
  }, [
    hasPendingCalculations,
    isCalculating,
    calculationsToCopy,
    activeMonth,
    updateCopyData,
  ]);

  const trackCalculationEvent = useTrackCalculation(portMap);

  const triggerCalculation = async (period?: string) => {
    if (!payload) {
      return;
    }

    if (isMultiModeInput(payload)) {
      const ports = payload.ports.map((port) => ({
        ...port,

        operations: port.operations.map((operation) => ({
          ...operation,

          cadence: isSet(operation.cadence)
            ? `${operation.cadence}`
            : undefined,
          terms: isSet(operation.terms) ? operation.terms : undefined,
          portDA: isSet(operation.portDA) ? operation.portDA : undefined,
          draft: isSet(operation.draft) ? operation.draft : undefined,

          tolerance: isSet(operation.tolerance)
            ? operation.tolerance
            : undefined,
          commodityPrice: isSet(operation.commodityPrice)
            ? operation.commodityPrice
            : undefined,

          useVesselGear: undefined,
        })),
      }));

      // If its a CFR optimizer calculation, make sure to put commodity price from preceding load
      //  operation into the appropriate discharge operations
      if (ctx.isCFROptimizer) {
        const commodityPrices = new Map<string, number>();
        const tolerances = new Map<string, number>();

        for (const port of ports) {
          const sortedOperationIndexes = port.operations
            .map((_, i) => i)
            .sort(
              // Discharge operations first
              (a, b) => {
                if (port.operations[a].operation === "D") {
                  return -1;
                }

                if (port.operations[b].operation === "D") {
                  return 1;
                }

                return 0;
              }
            );

          for (const i of sortedOperationIndexes) {
            const operation = port.operations[i];

            if (!operation.commodity) {
              continue;
            }

            if (operation.operation === "L" && operation.tolerance) {
              tolerances.set(operation.commodity, operation.tolerance);
            } else if (operation.operation === "D") {
              operation.tolerance = tolerances.get(operation.commodity) ?? 0;
            }

            if (operation.commodityPrice) {
              commodityPrices.set(
                operation.commodity,
                operation.commodityPrice
              );
            } else {
              operation.commodityPrice =
                commodityPrices.get(operation.commodity) ?? 0;
            }
          }
        }
      }

      const submitPayload = {
        period: period ?? activeMonth,
        mode: CalculatorMode.multi,

        useCache: true,
        db_version: undefined,

        hireOverride: payload.hire,
        sfoOverride: payload.sfoPrice,
        mgoOverride: payload.mgoPrice,

        optimizerMode: ctx.isCFROptimizer ? payload.optimizerMode : undefined,

        ports,
      };

      trackCalculationEvent(
        ctx.isCFROptimizer ? "cfr" : "combo",
        payload.mode,
        submitPayload
      );

      await onSubmit(submitPayload as any);
      return;
    }

    const submitPayload = {
      db_version: undefined,

      mode: CalculatorMode.advanced,
      cargo: `${payload.quantity ?? ""}`,
      tolerance: `${payload.tolerance ?? ""}`,
      commodity: payload.commodity,

      period: period ?? activeMonth,

      load_port: payload.startPort.port,
      disch_port: payload.endPort.port,

      // only include advanced fields if not in simple mode
      ...(payload.mode !== CalculatorModeString.Simple
        ? {
            load_cadence: payload.startPort.cadence,
            load_terms: payload.startPort.terms,

            disch_cadence: payload.endPort.cadence,
            disch_terms: payload.endPort.terms,

            loadPortDA: payload.startPort.portDA,
            loadPortDraft: payload.startPort.draft,
            // loadPortUseVesselGear: undefined, // FIXME: no field for it in UI design

            dischPortDA: payload.endPort.portDA,
            dischPortDraft: payload.endPort.draft,
            // dischPortUseVesselGear: undefined, // FIXME: no field for it in UI design

            hireOverride: payload.hire,
            sfoOverride: payload.sfoPrice,
            mgoOverride: payload.mgoPrice,
            // ballastBonusOverride: payload.ballastBonus, // FIXME: no field for it in UI design
          }
        : {
            load_cadence: undefined,
            load_terms: undefined,
            disch_cadence: undefined,
            disch_terms: undefined,
            loadPortDA: undefined,
            loadPortDraft: undefined,
            dischPortDA: undefined,
            dischPortDraft: undefined,
            hireOverride: undefined,
            sfoOverride: undefined,
            mgoOverride: undefined,
          }),

      useCache: true,
    };

    trackCalculationEvent("single", payload.mode, submitPayload);

    await onSubmit(submitPayload);
  };

  const changeMonth = (newMonth: string) => {
    setActiveMonth(newMonth);

    if (payload) {
      triggerCalculation(newMonth);
    }
  };

  useEffect(() => {
    if (payload) {
      triggerCalculation(
        isMultiModeInput(payload) ? payload.period : undefined
      );
    }
  }, [payload]);

  const [loadingItems, freightRates, range] = useMemo(() => {
    const loadingItems = Object.entries(
      periodFreightRates as Record<string, true | number>
    )
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);

    const rates = Object.fromEntries(
      Object.entries(
        periodFreightRates as Record<string, true | number>
      ).filter(([key, value]) => value !== true)
    ) as Record<string, number>;

    if (isCalculating || hasPendingCalculations) {
      for (const key of monthsArray) {
        if (!rates[key.value] && !loadingItems.includes(key.value)) {
          loadingItems.push(key.value);
        }
      }
    }

    return [
      loadingItems,
      rates,
      {
        min: Math.min(...Object.values(rates)),
        max: Math.max(...Object.values(rates)),
      },
    ];
  }, [periodFreightRates, isCalculating, hasPendingCalculations]);

  return (
    <div>
      <div className="flex flex-col gap-7">
        {ctx?.isCFROptimizer ? null : (
          <MonthSelector
            maxValue={range.max}
            minValue={range.min}
            activeMonth={activeMonth}
            setActiveMonth={changeMonth}
            months={monthsArray}
            isLoading={isCalculating || hasPendingCalculations}
            loading={loadingItems}
            freightRates={freightRates}
          />
        )}

        <div className="py-0.5">
          {ctx?.isCFROptimizer && (isCalculating || hasPendingCalculations) ? (
            <div>
              <div className="flex items-center gap-4 flex-col mb-2">
                <div>
                  <Ship className="h-16 w-16 text-primary-200 animate-bounce" />
                </div>

                <div className="text-frGrey-400 text-lg">
                  {optimizerStatus?.status
                    ? `${upperFirst(optimizerStatus.status)}...`
                    : "Initializing..."}
                </div>

                <div className="h-1 bg-frGrey-550 mt-2 w-full">
                  <div
                    className="h-1 bg-primary-200 transition-width duration-200 ease-in-out"
                    style={{
                      width: `${optimizerStatus?.progress || 0}%`,
                    }}
                  />
                </div>

                <ScrollIntoViewWhenAppears />
              </div>
            </div>
          ) : null}

          <CalcError error={calculationError} />

          <ResultsList
            showWithinContract={hasToleranceParam}
            results={plans ?? []}
            isLoading={loadingItems?.includes(activeMonth)}
            vesselMap={vesselMap}
            sortModalOpen={sortModalOpen}
            setSortModalOpen={setSortModalOpen}
            filterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
            calculationId={
              calculationId ?? "00000000-0000-0000-0000-000000000000"
            }
          />
        </div>
      </div>
    </div>
  );
}

const CalcError = ({
  error,
}: {
  error: string | { statusCode: number; message: string } | undefined | null;
}) => {
  if (!error) {
    return null;
  }

  let message: string;
  let level = "error";

  if (typeof error === "string") {
    message = error;
  } else {
    message = error.message;
    level = error.statusCode === 409 ? "info" : "error";
  }

  if (level === "info") {
    return <FormInfo message={message} />;
  }

  return <FormError message={message} />;
};

const ScrollIntoViewWhenAppears = () => {
  const divRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (divRef.current) {
        scrollToElement(divRef.current, null, -16);
      }
    }, 200);
  }, []);

  return <div ref={divRef} />;
};

const upperFirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export type ColumnDefinition = {
  key: string;

  label: string;
  cardLabel?: () => ReactNode;

  field: (
    item: VoyageResult,
    vesselMap: Record<string, VesselResponse>
  ) => string;
  sortValue?: (
    item: VoyageResult,
    vesselMap: Record<string, VesselResponse>
  ) => string | number | boolean | null;

  renderField?: (value: string, item: VoyageResult) => React.ReactNode;

  mobileOrder: number;

  width: number;

  resultAlign: "left" | "right";
  filterable?: boolean;
  className?: string;
  mobileClassName?: string;
};

export const columns: ColumnDefinition[] = [
  {
    label: "Vessel Size",
    key: "shipSize",
    filterable: true,
    field: (plan, vesselMap) =>
      `${plan.shipSize} (DWT: ${vesselMap[plan.shipSize].DWT})`,
    renderField: (value: string, item) => {
      const [name, dwtLabel] = value.split(" (D");

      return (
        <span>
          {name}
          <span className="text-frGrey-400 max-md:hidden">
            {" (D"}
            {dwtLabel}
          </span>

          <SizeRestrictionItineraryWarning voyage={item.debugData} />
        </span>
      );
    },
    sortValue: (plan, vesselMap) =>
      vesselMap[plan.shipSize].DWT ?? plan.shipSize,
    width: 0.2,
    resultAlign: "left",

    mobileOrder: 1,
  },
  {
    label: "Cargo intake (MTS)",
    cardLabel: () => (
      <>
        Cargo Intake
        <span className="max-md:inline hidden max-[400px]:hidden"> (MTS)</span>
      </>
    ),
    key: "cargoIntake",
    filterable: true,
    sortValue: (plan: VoyageResult) => plan.debugData.cargoIntake,
    field: (plan: VoyageResult) => {
      return plan.debugData.cargoIntake !== null
        ? formatNumberWithCommas(plan.debugData.cargoIntake)
        : "N/A";
    },
    width: 0.13,
    resultAlign: "right",

    mobileOrder: 2,
  },
  {
    label: "Within Tolerance",
    key: "isWithinTolerance",
    filterable: true,
    sortValue: (plan: VoyageResult) => plan.isWithinTolerance,
    field: (plan: VoyageResult) => (plan.isWithinTolerance ? "Yes" : "No"),
    renderField: (value: string, plan: VoyageResult) => {
      return (
        <span>
          {!plan.isWithinTolerance ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span className="flex items-center gap-0.5 justify-end">
                    No
                    <Icon
                      icon="InfoSquareRounded"
                      className="h-4 w-4 ml-1 text-frGrey-400 hover:text-frGrey-300"
                    />
                  </span>
                </TooltipTrigger>
                <TooltipContent className="bg-frGrey-800 border-frGrey-500 max-w-60 font-normal text-sm">
                  <p>{plan.isWithinToleranceDetails}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            "Yes"
          )}
        </span>
      );
    },
    width: 0.12,
    resultAlign: "right",

    mobileOrder: 3,
  },
  {
    label: "Canal/ Route Point",
    key: "routePoint",
    filterable: true,
    field: (plan: VoyageResult) => plan.routePoint.replaceAll("_", " "),
    width: 0.18,
    resultAlign: "left",

    className: "sm:text-ellipsis sm:overflow-hidden",
    mobileClassName: "max-sm:text-ellipsis max-sm:overflow-hidden max-sm:pr-2",

    mobileOrder: 5,
  },
  {
    label: "Hire",
    key: "hire",
    filterable: true,
    sortValue: (plan: VoyageResult) => plan.debugData.hire.value,
    field: (plan: VoyageResult) =>
      formatNumberWithCommas(plan.debugData.hire.value),
    renderField: (value: string) =>
      valueSuffixConstructor(value, "$", true, "max-[400px]:hidden"),
    width: 0.12,
    resultAlign: "right",
    mobileClassName: "max-[400px]:pr-1",

    mobileOrder: 6,
  },
  {
    label: "Ballast Bonus",
    key: "ballastBonus",
    filterable: true,
    sortValue: (plan: VoyageResult) => plan.debugData.ballastBonus,
    field: (plan: VoyageResult) =>
      formatNumberWithCommas(plan.debugData.ballastBonus),
    renderField: (value: string) =>
      valueSuffixConstructor(value, "$", true, "max-[400px]:hidden"),
    width: 0.12,
    resultAlign: "right",

    mobileOrder: 7,
  },
  {
    label: "Days",
    key: "days",
    filterable: true,
    sortValue: (plan: VoyageResult) => plan.debugData.totalCost.costInDays,
    field: (plan: VoyageResult) =>
      formatNumberWithCommas(plan.debugData.totalCost.costInDays),
    width: 0.12,
    resultAlign: "right",

    mobileOrder: 8,
  },
  {
    label: "Freight Rate ($/ mt)",
    cardLabel: () => (
      <>
        Freight Rate
        <span className="max-md:inline hidden max-[400px]:hidden">
          {" "}
          ($/ mt)
        </span>
      </>
    ),
    key: "freightRate",
    filterable: true,
    sortValue: (plan: VoyageResult) => plan.price,
    field: (plan: VoyageResult) =>
      plan.price !== null ? formatNumberWithCommas(plan.price) : "N/A",
    width: 0.17,
    renderField: (value: string) => (
      <span className="relative w-full inline-block">
        <span className="opacity-0">
          {valueSuffixConstructor(value, "$/mt")}
        </span>
        <span className="big-label z-40 font-normal text-frGrey-300 max-lg:text-primary-200 text-2xl absolute left-0 -bottom-1.5 right-0">
          {valueSuffixConstructor(value, "$/mt", true, "max-md:hidden")}
        </span>
      </span>
    ),
    resultAlign: "right",

    mobileOrder: 4,
  },
];

const defaultFilters = {
  isWithinTolerance: "Yes",
};

const useFiltering = (
  results: VoyageResult[],
  columns: ColumnDefinition[],
  vesselMap: Record<string, VesselResponse>,
  showWithinContract: boolean
) => {
  const [activeFilters, setActiveFilters] =
    useState<Record<string, string>>(defaultFilters);

  const filterOptions = useMemo(() => {
    const filterCols = columns.reduce((acc, column) => {
      if (column.filterable) {
        acc[column.key] = [];
      }

      return acc;
    }, {} as Record<string, string[]>);

    // Populate options
    for (const result of results) {
      for (const column of columns) {
        if (column.filterable) {
          const key = column.key;

          const value = `${
            typeof column.field === "function"
              ? column.field(result, vesselMap)
              : result[column.field]
          }`;

          if (filterCols[key].indexOf(value) === -1) {
            filterCols[key].push(value);
          }
        }
      }
    }

    return filterCols;
  }, [columns, results, vesselMap]);

  const filtersActive = useMemo(() => {
    return Object.values(activeFilters).some((filter) => filter !== "");
  }, [activeFilters]);

  const filteredData = useMemo(() => {
    return results.filter((result) => {
      for (const [key, value] of Object.entries(activeFilters)) {
        if (value === "") {
          continue;
        }

        if (!showWithinContract && key === "isWithinTolerance") {
          continue;
        }

        const column = columns.find((col) => col.key === key);

        if (!column) {
          continue;
        }

        const fieldValue =
          typeof column.field === "function"
            ? column.field(result, vesselMap)
            : result[column.field];

        if (`${fieldValue}` !== value) {
          return false;
        }
      }

      return true;
    });
  }, [results, activeFilters, vesselMap]);

  const [sortBy, setSortBy] = useState<{
    field: string;
    direction: "asc" | "desc";
  } | null>(null); // by default we sort server side

  const sortedData = useMemo(() => {
    if (!sortBy) {
      return filteredData;
    }

    return filteredData.sort((a, b) => {
      const column = columns.find((col) => col.key === sortBy.field);

      if (!column) {
        return 0;
      }

      let aValue;
      let bValue;

      if (column.sortValue) {
        aValue = column.sortValue(a, vesselMap);
        bValue = column.sortValue(b, vesselMap);
      } else if (typeof column.field === "function") {
        aValue = column.field(a, vesselMap);
        bValue = column.field(b, vesselMap);
      } else {
        aValue = a[column.field];
        bValue = b[column.field];
      }

      if (aValue === bValue) {
        return 0;
      }

      if (aValue === null) {
        return 1;
      }

      if (bValue === null) {
        return -1;
      }

      return sortBy.direction === "asc"
        ? aValue > bValue
          ? 1
          : -1
        : aValue < bValue
        ? 1
        : -1;
    });
  }, [filteredData, sortBy, vesselMap]);

  return useMemo(
    () => ({
      sortBy,
      setSortBy,
      sortedData,
      filtersActive,
      filterOptions,
      clearFilters: () => {
        setActiveFilters({});
      },
      activeFilters,
      setActiveFilters,
    }),
    [
      sortBy,
      setSortBy,
      sortedData,
      filtersActive,
      filterOptions,
      activeFilters,
      setActiveFilters,
    ]
  );
};

export const MobileResultCard = ({
  columns,
  renderColumn,
  noHover,
  children,
  extra,
}: {
  columns: ColumnDefinition[];
  renderColumn: (column: ColumnDefinition, isMobile: boolean) => ReactNode;
  noHover?: boolean;
  children?: ReactNode;
  extra?: ReactNode;
}) => {
  const hasToleranceColumn = columns.find(
    (col) => col.key === "isWithinTolerance"
  );

  const node = (
    <div
      className={cn(
        "font-light",

        "grid gap-y-2 grid-cols-[30%,15%,20%,20%,15%]",

        {
          ["[grid-template-areas:'shipSize_cargoIntake_cargoIntake_freightRate_freightRate''routePoint_routePoint_hire_ballastBonus_days''routePoint_routePoint_none_mgo_sfo']"]:
            !hasToleranceColumn,
          ["[grid-template-areas:'shipSize_cargoIntake_cargoIntake_freightRate_freightRate''routePoint_routePoint_hire_ballastBonus_days''routePoint_routePoint_none_mgo_sfo''routePoint_routePoint_none_isWithinTolerance_isWithinTolerance']"]:
            hasToleranceColumn,
        },
        {
          "group hover:bg-frGrey-800 transition-colors transition-bg duration-150 ease cursor-pointer":
            !noHover,
          "p-4 py-5 bg-frGrey-990 rounded-md": !noHover,
          "lg:hidden": !noHover,
        }
      )}
    >
      {columns
        .slice()
        .sort((a, b) => a.mobileOrder - b.mobileOrder)
        .map((item, i) => renderColumn(item, true))}
      {extra}
    </div>
  );

  if (noHover) {
    return (
      <div className="p-4 py-5">
        {node}
        {children}
      </div>
    );
  }

  return node;
};

export const renderCardColumn = (
  column: ColumnDefinition,
  isMobile: boolean,
  result: VoyageResult,
  vesselMap: Record<string, VesselResponse>,
  totalWidth: number
) => {
  let child: ReactNode = column.field(result, vesselMap);

  if (column.renderField) {
    child = column.renderField(`${child}`, result);
  }

  const LabelNode = column.cardLabel ? column.cardLabel : () => column.label;

  return (
    <div
      className={cn("flex gap-2 max-[400px]:gap-0 items-center", {
        "items-center": column.key !== "routePoint",
        "items-start": column.key === "routePoint",

        [`[grid-area:${column.key}] `]: isMobile,

        "max-sm:justify-end":
          ["freightRate", "isWithinTolerance"].indexOf(column.key) !== -1,

        "max-lg:justify-end": isMobile && column.key === "freightRate",
        "max-sm:justify-self-end": isMobile && column.key === "days",
      })}
      key={column.key}
      style={{
        width: isMobile ? undefined : `${(column.width / totalWidth) * 100}%`,
      }}
    >
      {isMobile && column.key === "routePoint" ? (
        <div>
          <Icon icon="ArrowMoveDown" className="h-6 w-6 text-frGrey-300" />
        </div>
      ) : null}

      <div
        className={cn("flex flex-col gap-1 w-full", {
          "text-left": column.key !== "freightRate" && column.key !== "days",
          "text-right": column.key === "freightRate",

          "max-sm:text-right":
            ["freightRate", "isWithinTolerance"].indexOf(column.key) !== -1,
        })}
      >
        <div className="flex-grow" />
        <div
          className={cn("value leading-none", {
            "text-sm": isMobile,
            [column.mobileClassName ?? ""]: isMobile && column.mobileClassName,
            [column.className ?? ""]: !isMobile && column.className,
          })}
        >
          {child ?? null}
        </div>
        <label
          className={cn("block text-frGrey-400 h-3.5 truncate", {
            "max-lg:text-primary-200": column.key === "freightRate",
            "text-sm leading-none": !isMobile,
            "text-xs leading-none": isMobile,
          })}
        >
          <LabelNode />
        </label>
      </div>
    </div>
  );
};

export const ResultCard = ({
  result,
  vesselMap,
  totalWidth,
  renderedColumns,
  calculationId,
}: {
  result: VoyageResult;
  vesselMap: Record<string, VesselResponse>;
  totalWidth: number;
  renderedColumns: ColumnDefinition[];
  calculationId: string;
}) => {
  const renderColumn = (column: ColumnDefinition, isMobile: boolean) =>
    renderCardColumn(column, isMobile, result, vesselMap, totalWidth);

  const ctx = useContext<CalculatorContextT | null>(CalcContext);

  const averageCFRPrice = result.cfrPrice ?? 0;
  const cfrAverageType: "exact" | "avg" | "distinct" =
    result.cfrPriceType ?? "distinct";

  return (
    <>
      <Link
        href={`/platform/freight/${calculationId}/${result.id}`}
        className="lg:hidden"
      >
        <MobileResultCard
          columns={renderedColumns}
          renderColumn={renderColumn}
        />
      </Link>

      <AccordionItem
        value={result.id}
        key={result.id}
        className="border-0 max-lg:hidden"
        data-accordion-item={result.id}
      >
        <AccordionTrigger
          className={cn(
            "flex gap-2 px-6 py-4 bg-frGrey-990 rounded-md group font-light ",
            "hover:bg-frGrey-800 transition-colors transition-bg duration-150 ease",
            "[&>*>svg]:duration-300 [&>*>svg]:ease-in-out [&[data-state=open]>*>svg]:rotate-180",
            "[&[data-state=open]]:bg-primary-200 [&[data-state=open]]:text-frGrey-990 [&[data-state=open]]:rounded-b-none",
            "[&[data-state=open]_label]:text-frGrey-800 [&[data-state=open]_.value]:text-frGrey-800 [&[data-state=open]_.big-label]:text-inherit [&[data-state=open]_.chevron-icon]:text-frGrey-990"
          )}
          icon={false}
          underline={false}
        >
          {renderedColumns
            .filter((item) => {
              if (ctx?.isCFROptimizer) {
                return item.key !== "hire" && item.key !== "ballastBonus";
              }

              return true;
            })
            .map((item, i) => renderColumn(item, false))}

          {ctx?.isCFROptimizer ? (
            <div className="flex gap-2 max-[400px]:gap-0 items-center max-sm:justify-end w-[18%]">
              {cfrAverageType === "exact" || cfrAverageType === "avg" ? (
                <div className="flex flex-col gap-1 w-full text-right max-sm:text-right">
                  <div className="flex-grow" />
                  <div className="value leading-none">
                    <span className="relative w-full inline-block">
                      <span className="opacity-0">
                        {cfrAverageType === "avg" ? "~" : ""}
                        {valueSuffixConstructor(
                          formatNumberWithCommas(averageCFRPrice),
                          "$/mt"
                        )}
                      </span>
                      <span className="big-label z-40 font-normal text-frGrey-300 max-lg:text-primary-200 text-2xl absolute left-0 -bottom-1.5 right-0">
                        {cfrAverageType === "avg" ? "~" : ""}
                        {valueSuffixConstructor(
                          formatNumberWithCommas(averageCFRPrice),
                          "$/mt"
                        )}
                      </span>
                    </span>
                  </div>
                  <label className="block text-frGrey-400 h-3.5 truncate max-lg:text-primary-200 text-sm leading-none">
                    Average CFR Price
                  </label>
                </div>
              ) : null}
              {cfrAverageType === "distinct" ? (
                <div className="flex flex-col gap-1 w-full text-right max-sm:text-right">
                  <div className="flex-grow" />
                  <div className="value leading-none">
                    <span className="relative w-full inline-block text-xs">
                      See CRF
                      <br />
                      price in
                      <br /> table below
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          <div>
            <Icon
              icon="ChevronDown"
              className="text-white h-6 w-6 chevron-icon"
            />
          </div>
        </AccordionTrigger>
        <AccordionContent>
          <DebugTable result={result} calculationId={calculationId} />
        </AccordionContent>
      </AccordionItem>
    </>
  );
};

const scrollToElement = (
  element: HTMLElement,
  parent: HTMLElement | null,
  yOffset: number | undefined
) => {
  const scrollTop = parent ? parent.scrollTop : window.scrollY;

  const top = element.getBoundingClientRect().top;

  const y = scrollTop + top + (yOffset ?? 0);

  (parent ?? window).scrollTo({ top: y, behavior: "smooth" });
};

export const ResultsList = ({
  showWithinContract,
  results,
  isLoading,
  vesselMap,

  sortModalOpen,
  setSortModalOpen,
  filterModalOpen,
  setFilterModalOpen,
  calculationId,
}: {
  calculationId: string;

  showWithinContract: boolean;
  results: VoyageResult[];
  vesselMap: Record<string, VesselResponse>;

  isLoading?: boolean;

  sortModalOpen?: boolean;
  setSortModalOpen?: (isOpen: boolean) => void;

  filterModalOpen?: boolean;
  setFilterModalOpen?: (isOpen: boolean) => void;
}) => {
  const ctx = useContext<CalculatorContextT | null>(CalcContext);

  const renderedColumns = useMemo(
    () =>
      columns.filter((column) =>
        showWithinContract ? true : column.key !== "isWithinTolerance"
      ),
    [showWithinContract]
  );

  const totalWidth = renderedColumns.reduce(
    (acc, column) => acc + column.width,
    0
  );

  const {
    sortBy,
    setSortBy,
    sortedData,
    clearFilters,
    filterOptions,
    activeFilters,
    setActiveFilters,
  } = useFiltering(results, columns, vesselMap, showWithinContract);

  const [sortModalField, setSortModalField] = useState<string | null>(null);
  const [sortModalDirection, setSortModalDirection] = useState<"asc" | "desc">(
    "asc"
  );

  useEffect(() => {
    if (sortModalOpen) {
      setSortModalField(sortBy?.field ?? null);
      setSortModalDirection(sortBy?.direction ?? "asc");
    }
  }, [sortModalOpen, sortBy]);

  const lastOpenItem = useRef<string | null>(null);
  const [openItem, setOpenItem] = useState<string | null>(null);

  const onOpenGroupChange = (value: string) => {
    setOpenItem(value);
  };

  const headerHeight = useMeasureHeaderHeight();

  useLayoutEffect(() => {
    if (openItem && lastOpenItem.current !== openItem) {
      const timeout = setTimeout(() => {
        lastOpenItem.current = openItem;

        const el = document.querySelector(
          `[data-accordion-item="${openItem}"]`
        );

        if (el) {
          scrollToElement(
            el as HTMLElement,
            document.getElementById("scroll-area"),
            -headerHeight - 16
          );
        }
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, [openItem, headerHeight]);

  if (isLoading && !results.length) {
    // todo: maybe a loader
    return null;
  }

  // TODO: no plans message & error handling

  return (
    <div className="max-md:px-4 relative">
      {/* Render the filters */}

      {ctx?.isCFROptimizer ? null : (
        <div className="flex gap-4 max-xl:hidden">
          {renderedColumns.map((column) => {
            const options =
              filterOptions[column.key]?.map((item) => ({
                label: item,
                value: item,
              })) ?? [];

            return (
              <CalculatorDropdown
                key={column.key}
                value={
                  options?.find(
                    (item) => item.value === activeFilters[column.key]
                  ) ?? null
                }
                setValue={(value) => {
                  setActiveFilters((prev) => ({
                    ...prev,
                    [column.key]:
                      activeFilters[column.key] === value?.value
                        ? ""
                        : value?.value ?? "",
                  }));
                }}
                choices={options}
                label={
                  <span
                    className="flex gap-2 items-center cursor-pointer"
                    role="button"
                    aria-label={`Sort by ${column.cardLabel ?? column.label}`}
                    onClick={() => {
                      setSortBy((prev) => ({
                        field: column.key,
                        direction:
                          prev?.field === column.key
                            ? prev.direction === "asc"
                              ? "desc"
                              : "asc"
                            : "asc",
                      }));
                    }}
                  >
                    {column.label}

                    {sortBy?.field === column.key ? (
                      <Icon
                        icon={
                          sortBy?.direction === "asc"
                            ? "SortAscending"
                            : "SortDescending"
                        }
                        className="h-4 w-4 text-frGrey-300"
                      />
                    ) : (
                      <Icon
                        icon="ArrowsSort"
                        className="h-4 w-4 text-frGrey-500"
                      />
                    )}
                  </span>
                }
                placeholder="All"
                emptyText="No results found."
                labelClassName="text-sm text-frGrey-500"
                plainLabel
                variant="square"
              />
            );
          })}
        </div>
      )}

      {/* Render the cards */}

      <Accordion
        type="single"
        collapsible
        className="mt-8 flex flex-col gap-4"
        onValueChange={onOpenGroupChange}
      >
        {sortedData.map((result) => (
          <ResultCard
            key={result.id}
            result={result}
            vesselMap={vesselMap}
            renderedColumns={renderedColumns}
            totalWidth={totalWidth}
            calculationId={calculationId}
          />
        ))}
      </Accordion>

      {/* Sort modal */}
      <AlertDialog open={sortModalOpen} onOpenChange={setSortModalOpen}>
        <AlertDialogContent className="max-[540px]:max-w-[90%]">
          <AlertDialogHeader>
            <AlertDialogDescription>
              <InputWrapper label="Order by:" plainLabel>
                <div className="flex gap-2">
                  <Select
                    value={sortModalField?.toLowerCase() ?? ""}
                    onValueChange={setSortModalField}
                  >
                    <SelectTrigger className="w-[180px] max-[540px]:w-2/3 max-[400px]:w-1/2">
                      <SelectValue placeholder="Order by">
                        {columns.find((col) => col.key === sortModalField)
                          ?.label ?? ""}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {columns.map((column) => (
                        <SelectItem
                          key={column.key}
                          value={column.key}
                          textValue={column.label}
                        >
                          {column.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <Select
                    value={sortModalDirection?.toLowerCase() ?? ""}
                    onValueChange={(value) => {
                      setSortModalDirection(value as "asc" | "desc");
                    }}
                  >
                    <SelectTrigger className="w-[180px] max-[540px]:w-1/3 max-[400px]:w-1/2">
                      <SelectValue placeholder="Order">
                        {sortModalDirection === "asc"
                          ? "Ascending"
                          : "Descending"}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="asc" textValue="Ascending">
                        Ascending
                      </SelectItem>
                      <SelectItem value="desc" textValue="Descending">
                        Descending
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </InputWrapper>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={() => {
                if (sortModalField) {
                  setSortBy({
                    field: sortModalField,
                    direction: sortModalDirection ?? "asc",
                  });
                }
              }}
            >
              Sort
            </AlertDialogAction>
          </AlertDialogFooter>

          <AlertDialogPrimitive.Cancel className="absolute -right-4 -top-4 rounded-full p-2 bg-frGrey-990 ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </AlertDialogPrimitive.Cancel>
        </AlertDialogContent>
      </AlertDialog>

      {/* Filter modal */}
      <AlertDialog open={filterModalOpen} onOpenChange={setFilterModalOpen}>
        <AlertDialogContent className="max-[540px]:max-w-[90%]">
          <AlertDialogHeader>
            <AlertDialogTitle>Filter:</AlertDialogTitle>
            <AlertDialogDescription className="flex gap-2 flex-col">
              {renderedColumns.map((column) => {
                const options =
                  filterOptions[column.key]?.map((item) => ({
                    label: item,
                    value: item,
                  })) ?? [];

                return (
                  <CalculatorDropdown
                    key={column.key}
                    value={
                      options?.find(
                        (item) => item.value === activeFilters[column.key]
                      ) ?? null
                    }
                    setValue={(value) => {
                      setActiveFilters((prev) => ({
                        ...prev,
                        [column.key]:
                          activeFilters[column.key] === value?.value
                            ? ""
                            : value?.value ?? "",
                      }));
                    }}
                    choices={options}
                    label={column.label}
                    placeholder="All"
                    emptyText="No results found."
                    labelClassName="text-sm text-frGrey-500"
                    plainLabel
                    variant="square"
                  />
                );
              })}
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogAction variant="dangerText" onClick={clearFilters}>
              Clear filters
            </AlertDialogAction>
          </AlertDialogFooter>

          <AlertDialogPrimitive.Cancel className="absolute -right-4 -top-4 rounded-full p-2 bg-frGrey-990 ring-offset-background focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </AlertDialogPrimitive.Cancel>
        </AlertDialogContent>
      </AlertDialog>

      {isLoading ? (
        <div className="absolute left-0 right-0 top-0 bottom-0 bg-frGrey-990/50 z-[42]" />
      ) : null}
    </div>
  );
};
