import { PortInput } from "@/app/(protected)/(routes)/(external)/platform/freight/_components/schema";
import { CalculatorInputs } from "@copmer/calculator-widget";

type MultiModeInputForSubmit = Record<string, any> & {
  ports: PortInput[];
};

type MultiModeAnalyticsPayload = Omit<MultiModeInputForSubmit, "ports"> & {
  portCount: number;

  [key: `port_${number}_unlocode`]: string;
  [key: `port_${number}_name`]: number;

  [key: `port_${number}_operationCount`]: number;

  [key: `port_${number}_operation_${number}_operation`]: string;
  [key: `port_${number}_operation_${number}_amount`]: number | undefined;
  [key: `port_${number}_operation_${number}_commodity`]: string | undefined;

  [key: `port_${number}_operation_${number}_cadence`]: number | undefined;
  [key: `port_${number}_operation_${number}_terms`]: string | undefined;

  [key: `port_${number}_operation_${number}_draft`]: number | undefined;
  [key: `port_${number}_operation_${number}_portDA`]: number | undefined;

  [key: `port_${number}_operation_${number}_tolerance`]: number | undefined;
  [key: `port_${number}_operation_${number}_commodityPrice`]:
    | number
    | undefined;

  [key: `port_${number}_operation_${number}_stowageFactor`]: number | undefined;
};

const getMultiModePayloadFields = (
  data: MultiModeInputForSubmit,
  portMap: Record<string, string>
): MultiModeAnalyticsPayload => {
  const portCount = data.ports.length;

  const portFields: MultiModeAnalyticsPayload = {
    portCount,

    ...data.ports.reduce(
      (
        acc: Omit<MultiModeAnalyticsPayload, "portCount">,
        port: PortInput,
        portIndex: number
      ) => {
        const portKey = `port_${portIndex + 1}`;

        const portFields = {
          [`${portKey}_unlocode`]: port.port,
          [`${portKey}_name`]: portMap[port.port ?? ""] || port.port,
          [`${portKey}_operationCount`]: port.operations.length,
        };

        const operationFields = port.operations.reduce(
          (acc, operation, operationIndex) => {
            const operationKey = `${portKey}_operation_${operationIndex + 1}`;

            return {
              ...acc,
              [`${operationKey}_operation`]: operation.operation,
              [`${operationKey}_amount`]: operation.amount,
              [`${operationKey}_commodity`]: operation.commodity,

              [`${operationKey}_cadence`]: operation.cadence,
              [`${operationKey}_terms`]: operation.terms,

              [`${operationKey}_draft`]: operation.draft,
              [`${operationKey}_portDA`]: operation.portDA,

              [`${operationKey}_tolerance`]: operation.tolerance,
              [`${operationKey}_commodityPrice`]: operation.commodityPrice,

              [`${operationKey}_stowageFactor`]: operation.stowageFactor,
            };
          },
          {}
        );

        return {
          ...acc,
          ...portFields,
          ...operationFields,
        };
      },
      {} as Omit<MultiModeAnalyticsPayload, "portCount">
    ),
  };

  return portFields;
};

type CalculatorInputsWithPortNames = CalculatorInputs & {
  load_port_name: string;
  disch_port_name: string;
};

const isMultiModeInput = (data: any): data is MultiModeInputForSubmit => {
  return !!data.ports;
};

export const convertCalculationDataToAnalyticsPayload = (
  data: CalculatorInputs | MultiModeInputForSubmit,
  portMap: Record<string, string>
) => {
  if (isMultiModeInput(data)) {
    const payloadFields = getMultiModePayloadFields(data, portMap);

    const returnVal: MultiModeAnalyticsPayload & {
      payload: MultiModeInputForSubmit;
    } = {
      payload: {
        ...data,
        ports: data.ports.map((port: PortInput) => ({
          ...port,
          name: portMap[port.port ?? ""] || port.port,
        })),
      },
      ...payloadFields,
    };

    return returnVal;
  }

  const withPortNames = {
    ...data,
    load_port_name: portMap[data.load_port] ?? data.load_port,
    disch_port_name: portMap[data.disch_port] ?? data.disch_port,
  };

  const returnVal: CalculatorInputsWithPortNames & {
    payload: CalculatorInputsWithPortNames;
  } = {
    payload: withPortNames,
    ...withPortNames,
  };

  return returnVal;
};
